.leftSideMenu {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border: 1px solid black;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 10000;
}

/* .leftSideMenu::-webkit-scrollbar {
  width: 8px;
}

.leftSideMenu::-webkit-scrollbar-track {
  background: rgb(196, 196, 196);
  border-left: 2px solid white;
  border-right: 2px solid white;
  border-radius: 3px;
}

.leftSideMenu::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 3px;
  border: 1px solid rgb(141, 141, 141);
}

@media screen and (min-width: 1200px) {
  .leftSideMenu {
    overflow: unset;
  } */
/* } */