.elevationSelect {
  display: flex;
  flex-direction: column;
  flex: 1 1 40%;
  width: 100%;
}

.elevationSelectHeader {
  min-height: 40px;
}

.elevationOptionContainer  {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  height: 0 1 60%;
  width: 100%;
}
