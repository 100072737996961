.stage {
  position: relative;
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000;
  border-radius: 5px;
  height: 25px;
  width: 25px;
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 25;
  cursor: pointer;
}
