.SelectionItem {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0;
  pointer-events: none;
}

.SelectionItem.enabled {
  cursor: pointer;
  pointer-events: auto;
}
.SelectionItem.enabled:hover {
  background: rgba(34, 38, 41, 0.2);
  /* border-right: 2px solid #0DDDDD; */
}

.SelectionItem.enabled img {
  opacity: 1;
}

.SelectionItem img {
  margin-bottom: 0.5rem;
  opacity: 0.5;
  width: 70px;
}

.SelectionItem span {
  color: #000;
  font-weight: 100;
  text-transform: capitalize;
  font-style: italic;
}

@media screen and (min-width: 34.375rem) { /* 550px */
  .SelectionItem {
    flex-direction: row;
    
  }

  .SelectionItem img {
    margin-left: 1rem;
  }

  .SelectionItem span {
    flex-grow: 1;
    text-align: center;
  }
}
