.homeDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* flex: 1 1 100%; */
}

.homeDetails > * {
  margin: 1px 0;
}

.sectionHeaderContainer {
  display: none;
}

.saveFormContainer {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 20;
}

.dropdownContainer {
  width: 100%;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 30%;
  max-height: 170px;
  font-size: 0.75rem;
}

.communityText {
  composes: detailValue from './ListItem/ListItem.module.css';
  color: #0075C9;
  border: none;
  justify-content: left;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex: 1 0 50%;
  width: 100%;
}

  .homeDetails {
    flex: 0 1 40%;
  }

  .sectionHeaderContainer {
    display: unset;
    flex: 0 0 15%;
    width: 100%;
  }

  .detailContainer {
    flex: 1 0 45%;
  }

  .buttonContainer {
    flex: 1 0 30%;
  }
