.menuPanel {
  height: 100%;
  width: 100%;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

.backIcon {
  width: 30px;
  height: auto;
}

.backButton {
  display: flex;
  height: 10%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}
