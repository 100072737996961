.progressBar {
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
  padding-bottom: 20px;
}

.progressBarBackground {
  justify-content: center;
  width: 50%;
  height: 25px;
  background-color: lightgrey;
  position: relative;
}

.progressBarFill {
  height: 25px;
  background-color: lightblue;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.percentDisplay {
  text-align: center;
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  top: 0;
  left: 50%;
  z-index: 2;
}

.popUpNotice {
  display: flex;
  flex-direction: column;
  align-items: center;
}
