.listItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex: 1 1 30px;
    font-size: 0.65rem;
    padding: 0 5px;
  }
  
  .detailTitle {
    flex: 0 0 45%;
    text-align: right;
    text-transform: capitalize;
  }
  
  .detailValue {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 50%;
    height: 20px;
    border-radius: 3px;
    border: 1px solid black;
  }

  /* @media screen and (min-width: 768px) {
    .listItem{
      font-size: 0.75rem;
    }
  } */

  @media screen and (min-width: 1200px) {
    .listItem {
      font-size: 0.75rem;
      flex: 0 1 40px;
    }
  }
  