.sideMenuButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 5px;
  /* background: linear-gradient(
    90deg, 
    rgba(15,15,15,1) 0%, 
    rgba(63,63,63,1) 30%, 
    rgba(63,63,63,1) 70%, 
    rgba(15,15,15,1) 100%
  ); */
}

.innerButton {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  border: 2px solid black;
  border-radius: 5px;
  background: #303030;
}

.disabled {
  cursor: not-allowed;
}

.buttonIcon {
  height: 70%;
  width: auto;
}