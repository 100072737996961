.selectionItem {
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-height: 2.2rem;
  width: 100%;
  color: black;
  font-size: .7rem;
  background-color: rgb(234, 234, 234);
  cursor: pointer;
  padding: 3px 0 3px 0;
}

.selectionItem:nth-child(even) {
  background-color: rgb(245, 245, 245);
}

.optionPrice {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #086EBB;
  min-height: 1.8rem;
  margin: 0 2px;
  color: white;
  flex: 0 0 15%;
  border-radius: 3px;
}

.optionName {
  flex: 1 0 50%;
  padding-left: 2%;
}

.disabled {
  composes: selectionItem;
  color: rgb(175, 175, 175);
  pointer-events: none;
}

.icons {
  margin-right: 5px;
}

.switchInput {
  display: none;
}

.buttonContainer {
  display: inline-block;
  flex-shrink: 0;
  height: 22px;
  position: relative;
  width: 22px;
  margin-right: 5px;
}

.slider {
  border: 1px solid #000;
  bottom: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.slider::before {
  bottom: 2px;
  content: "";
  height: 14px;
  left: 3px;
  position: absolute;
  width: 14px;
}

input:checked + .slider { 
  border: 1px solid #000;
}

input:checked + .slider::before {
  background: #000;
  border-radius: 50%;
}

.slider {
  border-radius: 50%;
}

.SlideItem .switch .slider::before {
  border-radius: 50%;
}
