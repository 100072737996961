.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 18px;
  box-shadow: 0 0 2px rgba(0, 0, 0, .7);
  margin-right: 1rem;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.25s linear;
  transition: 0.25s linear;
  border-radius: 5%;
  box-shadow: 1px 0 3px rgba(0, 0, 0, .7) inset;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  top: -1px;
  background-image: linear-gradient(#fff 0%,#5f5f5f 3%, #101010 100%);
  -webkit-transition: 0.25s linear;
  transition: 0.25s linear;
  border-radius: 10%;
  box-shadow: 0 0 .5px rgba(0, 0, 0, .5);
}

input:checked + .slider {
  background: rgb(0,70,123);
  background: linear-gradient( rgba(0,70,123,1) 0%, rgba(4,106,183,1) 20%, rgba(20,119,194,1) 96%, rgba(30,138,220,1) 100%);
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
  box-shadow: -2px 0 4px rgba(0, 0, 0, .5);
}

