.footer {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid #333333;
  justify-content: space-between;
  align-self: flex-end;
  max-height: 105px;
}

.disclaimerText {
  text-align: center;
  align-self: center;   
  font-family: "Brandon Grotesque";
  color: #adadad;
  font-style: italic;   
  font-size: 0.5rem;  
  flex: 1 1 95%;
  padding: 10px;
}

.footerImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 121px;
  padding: 0 5px;
}

.brandLogo {
  composes: footerImageContainer;
  border-left: 1px solid #333333;
  background: #989898;
}

.footerImage {
  width: auto;
  max-width: 100px;
}

@media screen and (min-width: 768px) {
  .disclaimerText {
    font-size: 0.5rem;
    /* padding: unset; */
  }
}

@media screen and (min-width: 1200px) {
  .disclaimerText {
    font-size: 0.7rem;
    /* padding: unset; */
  }
}
