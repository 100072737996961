.rightMenuTabs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.tabGap {
  flex: 0 0 2%;
  border-bottom: 1px solid black;
}
