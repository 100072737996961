.sectionHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  min-height: 40px;
  font-size: 0.8rem;
  color: #333333;
}

.title {
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.sectionHeader hr {
  width: 80%;
  align-self: center;
}

@media screen and (min-width: 768px) {
  .sectionHeader {
    justify-content: space-evenly;
  }

  .title {
    width: 90%;
  }
}
