.buttonPanel {
  height: 100%;
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  /* align-items: flex-start; */
  font-size: 1rem;
  text-transform: uppercase;
  /* border: 1px dashed orange; */
  /* box-sizing: unset; */
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48%;
  color: white;
}

.fullButton {
  composes: button;
  width: 100%;
  /* border: 2px dashed pink; */
}

.halfButton {
  composes: button;
  width: 49%;
  /* border: 2px dashed lightgreen; */
}