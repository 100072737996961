.touchMenuHeader {
  display: flex;
  align-items: center;
  width: 100%;
  color: white;
  font-size: .75rem;
  font-weight: 600;
  padding: 5px;
  background-color: #1A1A1A;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.webMenuHeader {
  composes: touchMenuHeader;
  justify-content: space-between;
  width: 70%;
  border-style: solid;
  color: white;
  border: 1px solid black;
  border-radius: 3px 3px 0 0;
  font-size: .6rem;
  font-style: italic;
  text-transform: capitalize;
  margin-top: 5px;
}

.openArrow {
  display: flex;
  width: fit-content;
  height: fit-content;
  justify-content: flex-end;
  transform: rotate(0deg);
}

.closedArrow {
  composes: openArrow;
  transform: rotate(270deg);
}

