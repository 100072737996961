.loadingIndicator {
  display: inline-block;
  position: relative;
  width: 100%; 
  height: 100%;
  z-index: 2147483647;
}

.loadingIndicator span {
  color: #fff;
  left: 50%;
  position: absolute;
  text-transform: capitalize;
  top: 50%;
  transform: translate(-50%, 100%);
}

.loadingIndicator div {
  position: absolute;
  top: 50vh;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #086EBB;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loadingIndicator div:nth-child(1) {
  left: 48%;
  animation: lds-ellipsis1 0.6s infinite;
}
.loadingIndicator div:nth-child(2) {
  left: 48%;
  animation: lds-ellipsis2 0.6s infinite;
}
.loadingIndicator div:nth-child(3) {
  left: 50%;
  animation: lds-ellipsis2 0.6s infinite;
}
.loadingIndicator div:nth-child(4) {
  left: 52%;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
