.selectionsGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.touchItemContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.webItemContainer {
  height: 100%;
  width: 95%;
  overflow: hidden;
  border: 1px solid black;
  border-radius: 0 4px 4px 4px;
}
