.Button {
  cursor: pointer;
  flex: 1 0 40%;
  max-height: 40px;
  width: 95%;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #B0B0B0;
  outline: none;
  font-size: 0.65rem;
  background-color: #00274D;
  color: white;
  position: relative;
  font-weight: 700;
  text-transform: uppercase;
  min-height: 30px;
}

.disabledButton {
  composes: Button;
  cursor: not-allowed;
  background-color: darkgrey;
}

.buttonText {
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1200px) {
  .Button {
    font-size: 0.75rem;
  }
}
