.sideMenuButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  height: 20px;
  font-size: 0.6rem;
  color: #0075C9;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.menuButton {
  cursor: pointer;
}
