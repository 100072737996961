.downloadBackground {
  align-items: center;
  background: rgba(0,0,0,0.3);
  color: #000;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.downloadBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #1A3464;
  padding: 25px;
  background-color: white;
  border-radius: 5px;
  width: 350px;
}

.text {
  padding-bottom: 20px;
}

.progressBarBackground {
  justify-content: center;
  width: 50%;
  height: 25px;
  background-color: lightgrey;
  position: relative;
}

.progressBarFill {
  height: 25px;
  background-color: lightblue;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.percentDisplay {
  text-align: center;
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  top: 0;
  left: 50%;
  z-index: 2;
}

.popUpNotice {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.downloadLink {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2.0rem;
  padding-bottom: 20px;
  background: rgba(255, 255, 255, 0.2);
}

.buttonRow {
  display: flex;
}

.buttonRow button {
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #B0B0B0;
  outline: none;
  font-size: 0.65rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  text-decoration: none;
  margin: 0 1rem;
  text-transform: uppercase;
  min-height: 30px;
}

.cancelButton {
  color: #555;
  background-color: #eee;
}

.downloadButton {
  color: white;
  background-color: #00274D;
}


