.PDFDownloader {
  /* height: 100%; */
  /* width: 100%; */
  /* position: absolute;
  top: 0;
  left: 0; */
  /* visibility: hidden; */
}

.pdfContainer {
  height: 100%;
  width: 100%;
}

.button {
  background: none;
  border: none;
  height: 0;
  width: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
