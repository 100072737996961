.viewToggle {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  position: relative;
  z-index: 10000;
}

.viewName {
  display: flex;
  flex: 1 0 40%;
  justify-content: center;
}

.viewToggleButtonContainer {
  display: unset;
  height: 50px;
  width: 50px;
}

@media screen and (min-width: 800px) {
  .viewToggle {
    font-size: 1.4rem;
  }
  .viewName {
    flex: 0 0 40%;
  }
}

@media screen and (min-width: 1200px) {
  .viewToggle {
    font-size: 1.5rem;
  }
  .viewName {
    flex: 0 0 20%;
  }
}
