form {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #1A3464;
  padding: 25px;
  background-color: white;
  border-radius: 5px;
  width: 350px;
}

.saveForm p {
  color: #1A3464;
  padding-bottom: 15px;
}

.formInput {
  display: unset;
  width: 100%;
  padding: 0.75rem 1.25rem;
  margin: 0.25rem 0;
  box-sizing: border-box;
  font-size: 1rem;
  min-height: 20px;
  min-width: 20px;
}

.error {
  composes: formInput;
  border: 2px solid red;
}

.buttonRow {
  display: flex;
}

.buttonRow button {
  border-radius: 5px;
  font-weight: 500;
  padding: 0.5rem 1rem;
  text-decoration: none;
  margin: 0 1rem;
}

.saveButton {
  cursor: pointer;
  color: #fff;
  background-color: #1A3464;
}

.saveButtonDisabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #aaa;
}

.cancelButton {
  cursor: pointer;
  color: #555;
  background-color: #eee;
}
