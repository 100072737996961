.modelName {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  text-transform: capitalize;
  color: rgb(0, 39, 77);
  font-weight: 500;
  /* font-family: "Eames Century Modern"; */
}
