.menuTab {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border-style: solid;
  color: white;
  border: 1px solid black;
  border-radius: 3px 3px 0 0;
  font-size: .8rem;
  padding: 5px;
  background: linear-gradient(
    90deg, 
    rgba(15,15,15,1) 0%, 
    rgba(63,63,63,1) 30%, 
    rgba(63,63,63,1) 70%, 
    rgba(15,15,15,1) 100%
  );
}

.closedMenuTab {
  composes: menuTab;
  border-radius: 3px;
}

.openArrow {
  display: flex;
  width: fit-content;
  height: fit-content;
  transform: rotate(0deg);
  padding-right: 5px;
}

.closedArrow {
  composes: openArrow;
  transform: rotate(270deg);
  margin-bottom: 4px;
}

.tabText {
  text-transform: capitalize;
  flex: 1 1 50%;
  font-size: 0.8rem;
}

.headingWrapper {
  align-items: center;
  display: flex;
  margin-left: 1.5rem;
}

.headingDivider {
  padding-right: .75rem;
  padding-bottom: 3px;
  margin: 0;
  font-size: 30px;
  font-weight: 150;
  text-align: center;
  color: rgba(0, 0, 0, .9);
}