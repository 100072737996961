.FloorplanGraphic {
  background: #fff;
  height: 3600px;
  position: fixed;
  left: 2000;
  top: 2000;
  width: 3600px;
  z-index: -1;
}

.FloorplanGraphic canvas {
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}
