.menuTab {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 49%;
  font-size: 0.75rem;
  border-width: 1px 1px 0 1px;
  border-color: #000;
  border-style: solid;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: black;
  color: white;
  font-style: italic;
  text-align: center;
}

.selectedTab {
  composes: menuTab;
  background-color: white;
  color: black;
}