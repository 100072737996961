.exteriorStage {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.exteriorStageImg {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
}
