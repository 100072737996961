.app {
  height: 100%;
  /* max-height: 900px; */
  width: 100%;
  background-color: #fff;
  position: relative;
}

.transparentApp {
  composes: app;
  background-color: transparent;
}

.savingIndicator {
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.generalIndicator {
  composes: savingIndicator;
  color: black;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2147483647;
}
