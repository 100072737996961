.panelList {
  height: 90%;
  /* border: 1px solid orange; */
  overflow-y: auto;
}

.panelList::-webkit-scrollbar {
  width: 8px;
}

.panelList::-webkit-scrollbar-track {
  background: rgb(196, 196, 196);
  border-left: 2px solid white;
  border-right: 2px solid white;
  border-radius: 3px;
}

.panelList::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 3px;
  border: 1px solid rgb(141, 141, 141);
}
