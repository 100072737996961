.brandLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90px;
  width: 100%;
}

.logoImg {
  height: auto;
  width: 90%;
}

/* @media screen and (min-width: 768px) {
  .brandLogo {
    display: flex;
    background-color: #000;
    justify-content: center;
    align-items: center;
  }
} */
