* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* overflow-x: hidden; */
  font-family: 'Avenir', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
    body {
        overflow-y: auto;
    }
}

html, body, #root {
 height: 100%;
 width: 100%;
 margin: 0;
 padding: 0;
 background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Avenir";
  src: url("./fonts/Avenir-Roman.ttf") format("ttf")
}
/* 
@font-face {
  font-family: "Eames Century Modern";
  src: url("/fonts/EamesCenturyModern-Regular.otf") format("otf")
} */