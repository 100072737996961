.OptionItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0;
  width: 95%;
}
.OptionItem:hover {
  background: rgba(34, 38, 41, 0.5);
  border-right: 2px solid #0DDDDD;
}

.OptionItem img {
  margin-bottom: 0.5rem;
  width: 70px;
}
.OptionItem.border img {
  border: 2px solid #0DDDDD;
}

.OptionItem span {
  color: #000;
  font-weight: 100;
  text-align: center;
  text-transform: capitalize;
  font-style: italic;
}

@media screen and (min-width: 34.375rem) { /* 550px */
  .OptionItem {
    flex-direction: row;
  }

  .OptionItem img {
    margin-left: 1rem;
  }

  .OptionItem span {
    flex-grow: 1;
    text-align: center;
  }
}
