.elevationOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 45%;
  margin: 2.5%;
  cursor: pointer;
}

.overlayContainer {   
  position: relative;
  height: 100%;
  width: 100%;
}

.textDisplay {
  font-size: 0.75rem;
  overflow-y: hidden;
}

.boxSelected:after {
  position: absolute;
  content:"";
  top: 0;
  left: 0;
  width: 95%;
  height: 95%;
  background: rgba(0, 117, 210, 0.3);
  border: 3px solid #0075C9;
}

.elevImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.elevImage img {
  width: 100%;
  height: auto;
  background-position: center;
  border: 1px solid #333333;
}
